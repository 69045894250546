// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-ja-js": () => import("./../../../src/pages/about/index.ja.js" /* webpackChunkName: "component---src-pages-about-index-ja-js" */),
  "component---src-pages-access-index-en-js": () => import("./../../../src/pages/access/index.en.js" /* webpackChunkName: "component---src-pages-access-index-en-js" */),
  "component---src-pages-access-index-ja-js": () => import("./../../../src/pages/access/index.ja.js" /* webpackChunkName: "component---src-pages-access-index-ja-js" */),
  "component---src-pages-booking-index-en-js": () => import("./../../../src/pages/booking/index.en.js" /* webpackChunkName: "component---src-pages-booking-index-en-js" */),
  "component---src-pages-booking-index-ja-js": () => import("./../../../src/pages/booking/index.ja.js" /* webpackChunkName: "component---src-pages-booking-index-ja-js" */),
  "component---src-pages-couples-therapy-index-en-js": () => import("./../../../src/pages/couples-therapy/index.en.js" /* webpackChunkName: "component---src-pages-couples-therapy-index-en-js" */),
  "component---src-pages-couples-therapy-index-ja-js": () => import("./../../../src/pages/couples-therapy/index.ja.js" /* webpackChunkName: "component---src-pages-couples-therapy-index-ja-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-individual-therapy-index-en-js": () => import("./../../../src/pages/individual-therapy/index.en.js" /* webpackChunkName: "component---src-pages-individual-therapy-index-en-js" */),
  "component---src-pages-individual-therapy-index-ja-js": () => import("./../../../src/pages/individual-therapy/index.ja.js" /* webpackChunkName: "component---src-pages-individual-therapy-index-ja-js" */),
  "component---src-pages-relationship-counseling-index-en-js": () => import("./../../../src/pages/relationship-counseling/index.en.js" /* webpackChunkName: "component---src-pages-relationship-counseling-index-en-js" */),
  "component---src-pages-relationship-counseling-index-ja-js": () => import("./../../../src/pages/relationship-counseling/index.ja.js" /* webpackChunkName: "component---src-pages-relationship-counseling-index-ja-js" */)
}

